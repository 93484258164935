<template>
  <div class="routeinfos">
    <HomeHeader :image-path="BPRoute.MainPic" :small="true" />
    <div class="infos">
      <template v-if="language=='de'">
        <h1>{{ BPRoute.de.Titel }}</h1>
        <p>{{ BPRoute.de.Kurzbeschreibung }}</p>
      </template>
      <template v-if="language=='pl'">
        <h1>{{ BPRoute.pl.Titel }}</h1>
        <p>{{ BPRoute.pl.Kurzbeschreibung }}</p>
      </template>
      <template v-if="language=='cz'">
        <h1>{{ BPRoute.cz.Titel }}</h1>
        <p>{{ BPRoute.cz.Kurzbeschreibung }}</p>
      </template>

      <div class="filter">
        <div class="filter-header">
          <div class="filter-button-container">
            <img v-if="!showfilter" alt="Filter" class="filter-button" src="./../assets/picto-filter.svg" title="Filter"
                 @click="showfilter=!showfilter"
            >
            <img v-if="showfilter" class="filter-button" src="./../assets/picto-filter-close.svg" alt="Filter"
                 title="Filter"
                 @click="showfilter=!showfilter"
            >
            <label class="titel" @click="showfilter=!showfilter">Übersicht filtern  </label>
          </div>

          <div class="sortierung">
            Sortieren nach
            <select v-model="sortingby">
              <option v-if="geolocationenabled" value="distance">Entfernung</option>
              <option value="Ort">Ort</option>
              <!--  <option value="">Vorgeschlagene Route</option>-->
            </select>
          </div>
        </div>
        <div class="filter-selector" :class="{ open: showfilter }">
          <div class="closer">
            <span @click="showfilter=!showfilter">minimieren  |  </span>
            <span @click="reset()">Filter entfernen</span>
          </div>
          <div class="filter-ort">
            <label class="ueberschrift">Ort</label>
            <div v-for="city in BPfilteredCities" class="item">
              <label><input v-model="filtercity" type="checkbox" :value="city"> {{ city }}</label>
            </div>
          </div>
          <div class="filter-kategorie">
            <label class="ueberschrift">Objektart</label>
            <div class="item">
              <label><input v-model="filterkategorie" type="checkbox" name="kategorie"
                            value="gastronomie"
              > Gastronomie</label>
            </div>
            <div class="item">
              <label><input v-model="filterkategorie" type="checkbox" name="kategorie" value="wohnhaus">
                Wohnhaus</label>
            </div>
            <div class="item">
              <label><input v-model="filterkategorie" type="checkbox" name="kategorie" value="museum">
                Museum</label>
            </div>
          </div>
          <div class="filter-istneu">
            <div>
              <label><input v-model="filterneu" type="checkbox" name="istneu" value="1"> Nur neue Objekte
                anzeigen</label>
            </div>
          </div>
        </div>
      </div>

      <div class="waypoints">
        <ul>
          <li v-for="waypoint in BPfilteredWaypoints" :key="waypoint.id">
            <table style="width: 100%">
              <tr>
                <td style="width: 20px"><img style="width: 20px" src="./../assets/picto-pfeil-link.svg"></td>
                <td>
                  <router-link :to="{ name: 'waypoint', params: { waypointId: waypoint.id }}">
                    <template v-if="language=='de'">
                      {{ waypoint.city }}, {{ waypoint.de.Titel }}
                      <div v-if="waypoint.de.Notiz">{{ waypoint.de.Notiz }}</div>
                    </template>
                    <template v-if="language=='pl'">
                      {{ waypoint.city }}, {{ waypoint.pl.Titel }}
                      <div v-if="waypoint.pl.Notiz">{{ waypoint.pl.Notiz }}</div>
                    </template>
                    <template v-if="language=='cz'">
                      {{ waypoint.city }}, {{ waypoint.cz.Titel }}
                      <div v-if="waypoint.cz.Notiz">{{ waypoint.cz.Notiz }}</div>
                    </template>
                    <div v-if="geolocationenabled && waypoint.distance" style="text-align:right;">
                      <img src="./../assets/location.svg" style="height: 10px;">{{ waypoint.distance | round }}
                    </div>
                  </router-link>
                </td>
              </tr>
            </table>
          </li>
          <div style="clear: both" />
        </ul>
      </div>
      <Punktkarte v-if="BPfilteredWaypoints.length" :waypoints="BPfilteredWaypoints" />
    </div>
  </div>
</template>

<script type="text/babel">

import Backbutton from '../components/Backbutton'
import Punktkarte from '../components/Punktkarte'
import HomeHeader from '../components/HomeHeader'

import sortBy from 'lodash/sortBy'

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {

  //console.log(lat1,lon1,lat2,lon2);
  function deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  var R = 6371 // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1)  // deg2rad below
  var dLon = deg2rad(lon2 - lon1)
  var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)

  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return d
}

export default {
  name: 'RouteView',
  components: {HomeHeader, Backbutton, Punktkarte},
  filters: {
    round: function (value) {
      if (!value) return ''
      return Math.floor(value) + ' km'
    },
  },
  props: ['routeId'],
  data: function () {
    return {
      showfilter: 0,
      filtercity: [],
      filterkategorie: [],
      filterneu: null,
      sortingby: 'Ort', // or distance
    }
  },
  computed: {
    language: function () {
      return this.$root.$i18n.locale
    },
    geolocationenabled: function () {
      return this.$root.currentlocation.lat && this.$root.currentlocation.lon
    },
    BPRoute: function () {
      let self = this
      return this.$root.BPRoutes.find((item) => item.id === self.routeId)
    },
    BPfilteredWaypoints() {
      // ALLE: return this.$root.BPPoints
      let bproute = this.BPRoute
      let BPPoints = this.$root.BPPoints
      let self = this
      //console.log(bproute.Punkte)
      let list = []

      bproute.Punkte.map(function (value, key) {
        let ret = BPPoints.filter((item) => item.id == value)
        //console.log(ret);
        if (!ret[0]) {
          //console.log(ret)
          return
        }
        if (self.filtercity.length && !(self.filtercity.includes(ret[0]['city']))) {
          //console.log('nicht in Stadt: ' + ret)
          return
        }

        //console.log(( ret[0]['typ_gastronomie'] === '1' ) + ' ungleich ' + self.filterkategorie.includes('gastronomie') );
        //console.log(( ret[0]['typ_wohnhaus'] === '1' ) + ' ungleich ' + self.filterkategorie.includes('wohnhaus') );
        //console.log(( ret[0]['typ_museum'] === '1' ) + ' ungleich ' + self.filterkategorie.includes('museum') );
        //console.log('-------------------')
        if (self.filterkategorie.length
            && !(
              (self.filterkategorie.includes('gastronomie') && (ret[0]['typ_gastronomie'] === '1') == self.filterkategorie.includes('gastronomie')) ||
              (self.filterkategorie.includes('wohnhaus') && (ret[0]['typ_wohnhaus'] === '1') == self.filterkategorie.includes('wohnhaus')) ||
              (self.filterkategorie.includes('museum') && (ret[0]['typ_museum'] === '1') == self.filterkategorie.includes('museum'))
            )
        ) {
          //console.log('nicht in kategorie: ' + ret)
          return
        }
        if (self.filterneu && (ret[0]['neu'] === '1') !== self.filterneu) {
          //console.log('nicht neu: ' + ret)
          return
        }

        let ort = ret[0]

        let distance = getDistanceFromLatLonInKm(self.$root.currentlocation.lat, self.$root.currentlocation.lon, ort['latitude'], ort['longitude'])

        ort['distance'] = distance

        list.push(ort)
        //console.log(value);
      })

      // soertieren
      if (this.sortingby == 'Titel')
        return sortBy(list, ['Titel'])

      if (this.sortingby == 'Ort')
        return sortBy(list, ['Ort'])

      if (this.sortingby == 'distance')
        return sortBy(list, ['distance'])

      return list

    },
    BPfilteredCities() {
      // ALLE: return this.$root.BPPoints
      let bproute = this.BPRoute
      let BPPoints = this.$root.BPPoints
      //console.log(bproute.Punkte)
      let list = []

      bproute.Punkte.map(function (ortsid, key) {
        let ret = BPPoints.filter((item) => item.id == ortsid)

        //console.log(ret);
        if (ret && ret[0]) {
          list.push(ret[0]['city'])
        }
        //console.log(value);

      })

      //doppeltes aussortieren
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index
      }

      var unique = list.filter(onlyUnique)

      return unique

    },
  },
  methods: {
    reset() {
      this.filterkategorie = []
      this.filterneu = null
      this.filtercity = []
    },
  },
}
</script>
