<template>
  <div class="karte">
    <l-map ref="map" :zoom="zoom" :center="center" :options="{scrollWheelZoom:false}">
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-feature-group ref="features" @ready="bounds">
        <l-marker v-for="(waypoint, index) in waypoints"
                  :key="waypoint.id"
                  :title="waypoint.de.Titel"
                  :lat-lng="[waypoint.latitude, waypoint.longitude]"
                  @click="goto(waypoint.id)"
        >
          <l-tooltip :content="waypoint.de.Titel" />
        </l-marker>
      </l-feature-group>
    </l-map>
  </div>
</template>

<script>
import {LMap, LTileLayer, LMarker,LTooltip, LFeatureGroup} from 'vue2-leaflet'

export default {
  name: 'Punktkarte',
  components: {
    LMap,
    LFeatureGroup,
    LTileLayer,
    LTooltip,
    LMarker,
  },
  props: {
    waypoints: {},
  },
  data() {
    return {
      map: null,
      zoom: 17,
      center: L.latLng(this.waypoints[0].latitude, this.waypoints[0].longitude),
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  },
  mounted() {
    // SVG MArkers
    // https://gist.github.com/clhenrick/6791bb9040a174cd93573f85028e97af

    this.$nextTick(() => {

      this.map = this.$refs.map.mapObject // work as expected
      let map = this.map

      let usermarker = L.circle(map.getCenter(), 10).addTo(map)

      this.map.on('locationfound', function (e) {

        // console.log(e);

        if (e.latlng) {
          let radius = e.accuracy / 2
          //L.circle(e.latlng, radius).addTo(map);
          // usermarker.setLatLng(e.latlng).bindPopup("" + radius + " meters away").openPopup();
          usermarker.setLatLng(e.latlng).bindPopup('' + radius + ' meter entfernt')

        }

      })
      this.map.locate({watch: true, enableHighAccuracy: true})

    })


  },
  methods: {
    bounds: function () {
      //console.log(this.$refs.features.mapObject.getBounds())

      this.$refs.map.mapObject.fitBounds(this.$refs.features.mapObject.getBounds().pad(0.07))
    },
    goto: function (waypointid) {

      this.$router.push({ name: 'waypoint', params: { waypointId: waypointid }})
    },
  },
}
</script>

<style scoped lang="scss">
  .karte {
    height: 500px;
    margin: 40px -80px;

    @media screen and (max-width: 768px) {
      margin-left: -30px;
      margin-right: -30px;
    }
  }
</style>
